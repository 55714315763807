import React from "react"
import howToStepsStyles from "./howToSteps.module.scss"
import { useStaticQuery, graphql } from "gatsby"

export default function HowToSteps() {
  const data = useStaticQuery(graphql`
    query howToUseSteps {
      allAirtable(
        sort: { fields: data___order, order: ASC }
        filter: { data: { section: { eq: "howToSteps" } } }
      ) {
        edges {
          node {
            id
            data {
              text
              description
              order
              image {
                url
                filename
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={howToStepsStyles.howToContainer} id="howToCta">
      {data.allAirtable.edges.map(edge => {
        return (
          <div
            className={howToStepsStyles.queryContainer}
            key={edge.node.id + "container"}
          >
            <div className={howToStepsStyles.card} key={edge.node.id + "card"}>
              <img
                className={howToStepsStyles.image}
                src={edge.node.data.image[0].url}
                alt={edge.node.data.image[0].filename}
              />
              <div
                className={howToStepsStyles.textContainer}
                key={edge.node.id + "textcontainer"}
              >
                <h2 className={howToStepsStyles.title}>
                  {edge.node.data.text}
                </h2>
                <p className={howToStepsStyles.description}>
                  {edge.node.data.description}
                </p>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

import React from "react"
import downloadStyles from "./download.module.scss"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function Download() {
  const data = useStaticQuery(graphql`
    query download {
      allAirtable(
        sort: { fields: data___order, order: ASC }
        filter: { data: { section: { eq: "download" } } }
      ) {
        edges {
          node {
            id
            data {
              text
              description
            }
          }
        }
      }
    }
  `)

  function gtag_report_conversion(url) {
    var callback = function() {
      if (typeof url != "undefined") {
        window.location = url
      }
    }
    window.gtag("event", "conversion", {
      send_to: "AW-687419116/NgR3CN-L7M8BEOzd5McC",
      event_callback: callback,
    })
    return false
  }

  function FirefacebookPixel() {
    window.fbq("track", "ClickToDownload")
  }
  function handleClick() {
    FirefacebookPixel()
    gtag_report_conversion()
  }

  const text = data.allAirtable.edges[0].node.data

  return (
    <div className={downloadStyles.container} id="download">
      <h2 className={downloadStyles.title}>{text.text}</h2>
      <p className={downloadStyles.text}>{text.description}</p>
      <div className={downloadStyles.downloadButtonsContainer}>
        <Link
          className={downloadStyles.downloadLink}
          to="/#contacto"
          title="Contactar"
        >
          Contactar
        </Link>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import contactStyles from "./contact.module.scss"
import { useStaticQuery, graphql } from "gatsby"

export default function Contact() {
  function facebookPixel() {
    window.fbq("track", "Lead")
  }
  function gtag_report_conversion(url) {
    var callback = function() {
      if (typeof url != "undefined") {
        window.location = url
      }
    }
    window.gtag("event", "conversion", {
      send_to: "AW-790590343/tCcnCKiJ_aEBEIfn_fgC",
      event_callback: callback,
    })
    return false
  }

  function handleSubmit(event) {
    event.preventDefault()
    if (!event.target.checkValidity()) {
      return
    }

    const data = {
      fields: {
        ID: "UNIO",
        "Nombre Completo": name,
        Email: email,
        Teléfono: phone,
        Comentarios: comments,
      },
    }
    gtag_report_conversion()
    facebookPixel()

    fetch("https://hook.integromat.com/s9q6ox9k7kroxy3kyax6ib542o9k4klw", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(data),
    }).then(response => {
      if (response.ok) {
        setApiResponse(true)
      } else setApiResponse(false)
    })
  }

  const data = useStaticQuery(graphql`
    query formTitle {
      allAirtable(
        sort: { fields: data___order, order: ASC }
        filter: { data: { section: { eq: "formTitle" } } }
      ) {
        edges {
          node {
            id
            data {
              text
              description
            }
          }
        }
      }
    }
  `)

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [comments, setComments] = useState("")
  const [apiResponse, setApiResponse] = useState("")

  if (apiResponse === "") {
    return (
      <div className={contactStyles.contactContainer} id="contacto">
        {data.allAirtable.edges.map(edge => {
          return (
            <div
              className={contactStyles.queryContainer}
              key={edge.node.id + "container"}
            >
              <h3 className={contactStyles.title}>{edge.node.data.text}</h3>
              <p className={contactStyles.subtitle}>
                {edge.node.data.description}
              </p>
            </div>
          )
        })}
        <form className={contactStyles.form} onSubmit={handleSubmit}>
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            name="name"
            className={contactStyles.input}
            placeholder="Nombre"
            autoComplete="name"
            required
          />
          <input
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            name="email"
            className={contactStyles.input}
            placeholder="Email"
            autoComplete="email"
            required
          />
          <input
            type="tel"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            name="email"
            className={contactStyles.input}
            placeholder="Celular"
            autoComplete="tel"
            required
          />
          <input
            type="text"
            value={comments}
            onChange={e => setComments(e.target.value)}
            name="comentarios"
            className={contactStyles.commentsInput}
            placeholder="Mensaje"
            required
          />
          <button type="submit" className={contactStyles.submit}>
            Enviar
          </button>
        </form>
      </div>
    )
  } else if (apiResponse === true) {
    return (
      <div className={contactStyles.contactContainer} id="contact">
        <p className={contactStyles.title}>
          Gracias {name}!
          <br />
          Tus datos se recibieron correctamente
        </p>
      </div>
    )
  } else {
    return (
      <div className={contactStyles.contactContainer} id="contact">
        <p className={contactStyles.title}>
          {name}!
          <br />
          Al parecer hubo un error en tu conexión a internet.
        </p>
        <p className={contactStyles.subtitle}>
          Por favor intenta de nuevo más tarde
        </p>
      </div>
    )
  }
}

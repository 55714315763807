import React from "react"
import packagesOptionsStyles from "./packagesOptions.module.scss"
import { useStaticQuery, graphql, Link } from "gatsby"

export default function PackagesOptions() {
  const data = useStaticQuery(graphql`
    query packages {
      allAirtable(
        sort: { fields: data___order, order: ASC }
        filter: { data: { section: { eq: "packages" } } }
      ) {
        edges {
          node {
            id
            data {
              text
              description
              order
              text2
            }
          }
        }
      }
    }
  `)
  function FirefacebookPixel() {
    window.fbq("track", "ClickToDownload")
  }
  function gtag_report_conversion(url) {
    var callback = function() {
      if (typeof url != "undefined") {
        window.location = url
      }
    }
    window.gtag("event", "conversion", {
      send_to: "AW-687419116/NgR3CN-L7M8BEOzd5McC",
      event_callback: callback,
    })
    return false
  }
  function handleClick() {
    FirefacebookPixel()
    gtag_report_conversion()
  }

  return (
    <div
      className={packagesOptionsStyles.packagesOptionsContainer}
      id="PackagesOptionsCta"
    >
      {data.allAirtable.edges.map(edge => {
        return (
          <div
            className={packagesOptionsStyles.queryContainer}
            key={edge.node.id + "container"}
          >
            <div className={packagesOptionsStyles.specialCard}>
              <h4 className={packagesOptionsStyles.packageType}>
                {edge.node.data.text}
              </h4>
              <div className={packagesOptionsStyles.packagePriceContainer}>
                <p
                  className={packagesOptionsStyles.specialPrice}
                >{`${edge.node.data.description}`}</p>
                <p
                  className={packagesOptionsStyles.packageCurrency}
                  key={edge.node.id + "currency"}
                >
                  MXN
                </p>
              </div>
              <div
                className={packagesOptionsStyles.packageTiming}
                key={edge.node.id + "timing"}
              >
                al mes
              </div>
              <div
                className={packagesOptionsStyles.packageExplanation}
                key={edge.node.id + "explanation"}
              >
                {edge.node.data.text2}
              </div>
              <Link
                to="/#contacto"
                title="Descarga Unio"
                className={packagesOptionsStyles.specialButton}
              >
                Contactar
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  )
}

import React from "react"
import whatsUnioTitleStyles from "./whatsUnioTitle.module.scss"
import { useStaticQuery, graphql } from "gatsby"

export default function WhatsUnioTitle() {
  const data = useStaticQuery(graphql`
    query queEsUnioTitulo {
      allAirtable(
        sort: { fields: data___order, order: ASC }
        filter: { data: { section: { eq: "queEsUnioTitulo" } } }
      ) {
        edges {
          node {
            id
            data {
              text
              description
            }
          }
        }
      }
    }
  `)

  const id = data.allAirtable.edges[0].node.id
  const title = data.allAirtable.edges[0].node.data.text
  const description = data.allAirtable.edges[0].node.data.description
  return (
    <div className={whatsUnioTitleStyles.container} key={id + 132}>
      <h2 className={whatsUnioTitleStyles.titleOne} key={id + 12}>
        {title}
      </h2>
      <h3 className={whatsUnioTitleStyles.titleTwo} key={id}>
        {description}
      </h3>
    </div>
  )
}

import React from "react"
import WhatsUnioTitle from "./WhatsUnioTitle"
import WhatsUnioText from "./WhatsUnioText"
import whatsUnioStyles from "./whatsUnio.module.scss"

export default function WhatsUnio() {
  return (
    <div className={whatsUnioStyles.whatsUnioContainer} id="queEsUnio">
      <div className={whatsUnioStyles.textContainer}>
        <WhatsUnioTitle />
        <WhatsUnioText />
      </div>
      <div className={whatsUnioStyles.mediaContainer}>
        {/* <iframe 
                    src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Ffacebook%2Fvideos%2F10153231379946729%2F&width=500&show_text=false&appId=589672491500440&height=280" 
                    width="90%" 
                    height="280" 
                    style={{border:"0", overflow:"hidden"}}
                    scrolling="no" 
                    frameBorder="0" 
                    // allowTransparency={true}
                    allow="encrypted-media" 
                    allowFullScreen={true}
                    title="Facebook video"
                    >
                </iframe> */}
      </div>
    </div>
  )
}

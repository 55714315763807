import React from "react"
import packagesStyles from "./packages.module.scss"
import PackagesTitle from "./PackagesTitle"
import PackagesOptions from "./PackagesOptions"

export default function Packages() {
  return (
    <div className={packagesStyles.packagesContainer}>
      <PackagesTitle />
      <PackagesOptions />
    </div>
  )
}

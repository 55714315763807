import React from "react"
import HowToCta from "./HowToCta"
import HowToSteps from "./HowToSteps"

export default function HowTo() {
  return (
    <div>
      <HowToCta />
      <HowToSteps />
    </div>
  )
}

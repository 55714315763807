import React from "react"
import packagesTitleStyles from "./packagesTitle.module.scss"
import { useStaticQuery, graphql } from "gatsby"

export default function PackagesTitle() {
  const data = useStaticQuery(graphql`
    query packagesCta {
      allAirtable(
        sort: { fields: data___order, order: ASC }
        filter: { data: { section: { eq: "packagesCta" } } }
      ) {
        edges {
          node {
            id
            data {
              text
            }
          }
        }
      }
    }
  `)

  return (
    <div className={packagesTitleStyles.packagesTitleContainer} id="paquetes">
      {data.allAirtable.edges.map(edge => {
        return (
          <h2 className={packagesTitleStyles.title} key={edge.node.id}>
            {edge.node.data.text}
          </h2>
        )
      })}
    </div>
  )
}

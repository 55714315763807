import React from "react"
import Hero from "../components/home/Hero"
import WhatsUnio from "../components/home/WhatsUnio"
import HowTo from "../components/home/HowTo"
import Download from "../components/home/Download"
// import Specs from "../components/home/Specs"
import Packages from "../components/home/Packages"
import Contact from "../components/home/Contact"
import SEO from "../components/seo"

export default function Index() {
  return (
    <div>
      <SEO
        title="Tu familia es nuestra pasión"
        description="Supervisa y controla el uso que le dan los menores de edad a la red. ¡Descarga la App gratuita!"
        url="/"
      />
      <Hero />
      <WhatsUnio />
      <HowTo />
      <Download />
      {/* <Specs /> */}
      <Packages />
      <Contact />
    </div>
  )
}

import React from "react"
import howToCtaStyles from "./howToCta.module.scss"
import { useStaticQuery, graphql } from "gatsby"

export default function HowToCta() {
  const data = useStaticQuery(graphql`
    query howToUseCta {
      allAirtable(
        sort: { fields: data___order, order: ASC }
        filter: { data: { section: { eq: "howToUseCta" } } }
      ) {
        edges {
          node {
            id
            data {
              description
              image {
                url
                filename
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={howToCtaStyles.howToContainer} id="howToCta">
      {data.allAirtable.edges.map(edge => {
        return (
          <div
            className={howToCtaStyles.queryContainer}
            key={edge.node.id + "container"}
          >
            <img
              className={howToCtaStyles.image}
              src={edge.node.data.image[0].url}
              alt={edge.node.data.image[0].filename}
              key={edge.node.id + "img"}
            />
            <h2 className={howToCtaStyles.description} key={edge.node.id}>
              {edge.node.data.description}
            </h2>
          </div>
        )
      })}
    </div>
  )
}

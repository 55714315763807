import React from "react"
import whatsUnioTextStyles from "./whatsUnioText.module.scss"
import { useStaticQuery, graphql } from "gatsby"

export default function WhatsUnioTitle() {
  const data = useStaticQuery(graphql`
    query queEsUnioText {
      allAirtable(
        sort: { fields: data___order, order: ASC }
        filter: { data: { section: { eq: "queEsUnioTexto" } } }
      ) {
        edges {
          node {
            id
            data {
              description
            }
          }
        }
      }
    }
  `)

  return (
    <div className={whatsUnioTextStyles.container}>
      <ul className={whatsUnioTextStyles.ul}>
        {data.allAirtable.edges.map(edge => {
          return (
            <li className={whatsUnioTextStyles.description} key={edge.node.id}>
              {edge.node.data.description}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
